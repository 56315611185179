import { useState } from 'react';
// import { Link } from 'react-router-dom';

import logo from './assets/images/PavathiLogo.svg';
import startupImg from './assets/images/startup.svg';
import features1Img from './assets/images/features-1.svg';
import features2Img from './assets/images/features-2.svg';
import './App.css';
import Modal from './components/UI/Modal/Modal';
// import 'assets/css/icons.css';
// import 'assets/css/app-creative.css';
// import 'assets/css/app-creative-dark.css';

function App() {

    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalName, setModalName] = useState('');

    const sendMessageHandler = e => {

    }

    const modalOpenHandler = modalName => {
        setModalIsOpen(true);
        setModalName(modalName);
    }

    const handleAfterOpen = (event, data) => {
        console.log(event, data);
    }

    const modalCloseHandler = status => {
        setModalIsOpen(false);
    }

  return (
    <div class="loading" data-layout-config='{"darkMode":true}'>

    <nav class="navbar navbar-expand-lg py-lg-3 navbar-dark bg-dark">
        <div class="container">

            <a href="#" class="navbar-brand mr-lg-5">
                <img src={logo} alt="" className='logo-dark' height="50" />
            </a>

            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown"
                aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                <i class="mdi mdi-menu"></i>
            </button>

            <div class="collapse navbar-collapse" id="navbarNavDropdown">

                <ul class="navbar-nav mr-auto align-items-center">
                    <li class="nav-item mx-lg-1">
                        <a class="nav-link active" href="#home">Home</a>
                    </li>
                    <li class="nav-item mx-lg-1">
                        <a class="nav-link" href="#features">Features</a>
                    </li>
                    <li class="nav-item mx-lg-1">
                        <a class="nav-link" href="#pricing">Pricing</a>
                    </li>
                    <li class="nav-item mx-lg-1">
                        <a class="nav-link" href="#faqs">FAQs</a>
                    </li>
                    {/* <li class="nav-item mx-lg-1">
                        <a class="nav-link" href="">Clients</a>
                    </li> */}
                    <li class="nav-item mx-lg-1">
                        <a class="nav-link" href="#contact">Contact</a>
                    </li>
                </ul>

                <ul class="navbar-nav ml-auto align-items-center">
                    <li class="nav-item mr-0">
                        {/* <a href="https://themes.getbootstrap.com/product/hyper-responsive-admin-dashboard-template/" target="_blank" class="nav-link d-lg-none">Purchase now</a> */}
                        <a href="https://pavathi.in/pavathi" class="btn btn-sm btn-light btn-rounded d-none d-lg-inline-flex">
                            {/* <i class="mdi mdi-basket mr-2"></i> */}
                            <i class="uil uil-sign-in-alt mr-1"></i>
                            Login
                        </a>
                    </li>
                </ul>

            </div>
        </div>
    </nav>

    <section class="hero-section">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-md-5">
                    <div class="mt-md-4">
                        <div>
                            <span class="badge badge-danger badge-pill">New</span>
                            <span class="text-white-50 ml-1">Welcome to Pavathi Home Page</span>
                        </div>
                        <h2 class="text-white font-weight-normal mb-4 mt-3 hero-title">
                            Digitized Sales Tracker
                        </h2>

                        <p class="mb-4 font-16 text-white-50">Pavathi aims to help people,
                        organizations and connected recharge related entities by optimizing the use of Recharge data
                        within the bounds of policy and regulation. This will help make decisions
                        and take beneficial actions that maximize the profits, stop Leakages and malfunctions.</p>

                        {/* <a href="" target="_blank" class="btn btn-success">Preview <i
                                class="mdi mdi-arrow-right ml-1"></i></a> */}
                    </div>
                </div>
                <div class="col-md-5 offset-md-2">
                    <div class="text-md-right mt-3 mt-md-0">
                        <img src={startupImg} alt="" class="img-fluid" />
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="py-5">
        <div class="container">
            <div class="row py-4">
                <div class="col-lg-12">
                    <div class="text-center">
                        <h1 class="mt-0"><i class="mdi mdi-infinity"></i></h1>
                        <h3>Pavathi application is <span class="text-primary">responsive</span> and easy to <span
                                className="text-primary">use.</span></h3>
                        <p class="text-muted mt-2">The clean reporting and tracking details allows easy usage of the
                            application.It is designed to
                            <br/>track every sequence of supply, sale, commissions, and incentive.</p>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-4">
                    <div class="text-center p-3">
                        <div class="avatar-sm m-auto">
                            <span class="avatar-title bg-primary-lighten rounded-circle">
                                <i class="uil uil-desktop text-primary font-24"></i>
                            </span>
                        </div>
                        <h4 class="mt-3">Responsive Layouts</h4>
                        <p class="text-muted mt-2 mb-0">An Elegant UI with smooth user experience & features you require to work with ease.
                        </p>
                    </div>
                </div>

                <div class="col-lg-4">
                    <div class="text-center p-3">
                        <div class="avatar-sm m-auto">
                            <span class="avatar-title bg-primary-lighten rounded-circle">
                                <i class="uil uil-vector-square text-primary font-24"></i>
                            </span>
                        </div>
                        <h4 class="mt-3">Based on Bootstrap UI</h4>
                        <p class="text-muted mt-2 mb-0">Pavathi UI is built using Twitter Bootstrap CSS, which provides a clean and elegant look.
                        </p>
                    </div>
                </div>

                <div class="col-lg-4">
                    <div class="text-center p-3">
                        <div class="avatar-sm m-auto">
                            <span class="avatar-title bg-primary-lighten rounded-circle">
                                <i class="uil uil-presentation text-primary font-24"></i>
                            </span>
                        </div>
                        <h4 class="mt-3">Creative Design</h4>
                        <p class="text-muted mt-2 mb-0">Design is simple and efficient. This will help you do your work efficiently, reducing human effort.
                        </p>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-4">
                    <div class="text-center p-3">
                        <div class="avatar-sm m-auto">
                            <span class="avatar-title bg-primary-lighten rounded-circle">
                                <i class="uil uil-apps text-primary font-24"></i>
                            </span>
                        </div>
                        <h4 class="mt-3">Multiple Applications</h4>
                        <p class="text-muted mt-2 mb-0">We at Paperfree solutions have multiple applications to offer to our clients.
                        </p>
                    </div>
                </div>

                <div class="col-lg-4">
                    <div class="text-center p-3">
                        <div class="avatar-sm m-auto">
                            <span class="avatar-title bg-primary-lighten rounded-circle">
                                <i class="uil uil-shopping-cart-alt text-primary font-24"></i>
                            </span>
                        </div>
                        <h4 class="mt-3">Accounting & Tracking</h4>
                        <p class="text-muted mt-2 mb-0">Hours of human work is reduced as Pavathi does all the tracking and accounting in minutes.
                        </p>
                    </div>
                </div>

                <div class="col-lg-4">
                    <div class="text-center p-3">
                        <div class="avatar-sm m-auto">
                            <span class="avatar-title bg-primary-lighten rounded-circle">
                                <i class="uil uil-grids text-primary font-24"></i>
                            </span>
                        </div>
                        <h4 class="mt-3">Dashboard View</h4>
                        <p class="text-muted mt-2 mb-0">We provide a Dashboard to view all the details of supply, sales, commission & incentive.
                        </p> 
                    </div>
                </div>
            </div>

        </div>
    </section>

    {/* <section class="py-5 bg-light-lighten border-top border-bottom border-light">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="text-center">
                        <h3>Flexible <span class="text-primary">Layouts</span></h3>
                        <p class="text-muted mt-2">There are three different layout options available to cater need for
                            any <br /> modern web
                            application.</p>
                    </div>
                </div>
            </div>

            <div class="row mt-4">
                <div class="col-lg-4">
                    <div class="demo-box text-center">
                        <img src="assets/images/layouts/layout-1.png" alt="demo-img"
                            class="img-fluid shadow-sm rounded" />
                        <h5 class="mt-3 f-17">Vertical Layout</h5>
                    </div>
                </div>

                <div class="col-lg-4">
                    <div class="demo-box text-center mt-3 mt-lg-0">
                        <img src="assets/images/layouts/layout-2.png" alt="demo-img"
                            class="img-fluid shadow-sm rounded" />
                        <h5 class="mt-3 f-17">Horizontal Layout</h5>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="demo-box text-center mt-3 mt-lg-0">
                        <img src="assets/images/layouts/layout-3.png" alt="demo-img"
                            class="img-fluid shadow-sm rounded" />
                        <h5 class="mt-3 f-17">Detached Layout</h5>
                    </div>
                </div>
            </div>

            <div class="row mt-4">
                
                <div class="col-lg-4">
                    <div class="demo-box text-center">
                        <img src="assets/images/layouts/layout-5.png" alt="demo-img"
                            class="img-fluid shadow-sm rounded" />
                        <h5 class="mt-3 f-17">Light Sidenav Layout</h5>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="demo-box text-center mt-3 mt-lg-0">
                        <img src="assets/images/layouts/layout-6.png" alt="demo-img"
                            class="img-fluid shadow-sm rounded" />
                        <h5 class="mt-3 f-17">Boxed Layout</h5>
                    </div>
                </div>

                <div class="col-lg-4">
                    <div class="demo-box text-center mt-3 mt-lg-0">
                        <img src="assets/images/layouts/layout-4.png" alt="demo-img"
                            class="img-fluid shadow-sm rounded" />
                        <h5 class="mt-3 f-17">Semi Dark Layout</h5>
                    </div>
                </div>
            </div>

        </div>
    </section> */}

    <section class="py-5" id='features'>
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="text-center">
                        <h1 class="mt-0"><i class="mdi mdi-heart-multiple-outline"></i></h1>
                        <h3>Features you'll <span class="text-danger">love</span></h3>
                        <p class="text-muted mt-2">Pavathi comes with elegant UI design and has multiple benefits
                        </p>
                    </div>
                </div>
            </div>
            <div class="row mt-2 py-5 align-items-center">
                <div class="col-lg-5">
                    <img src={features1Img} class="img-fluid" alt="" />
                </div>
                <div class="col-lg-6 offset-lg-1">
                    <h3 class="font-weight-normal">Inbuilt applications and pages</h3>
                    <p class="text-muted mt-3">Pavathi comes with a variety of reports and pages that help in tracking every sequence of supply, sale, commissions, and incentive.</p>

                    <div class="mt-4">
                        <p class="text-muted"><i class="mdi mdi-circle-medium text-primary"></i> Helps decision making & maximize profits</p>
                        <p class="text-muted"><i class="mdi mdi-circle-medium text-primary"></i> Reporting & Tracking Application</p>
                        <p class="text-muted"><i class="mdi mdi-circle-medium text-primary"></i> Dashboard, Transactions, Reports</p>
                        <p class="text-muted"><i class="mdi mdi-circle-medium text-primary"></i> Change Password, Update Company details</p>
                        <p class="text-muted"><i class="mdi mdi-circle-medium text-primary"></i> Login, Signup, Forgot password</p>
                    </div>

                    <a href="" class="btn btn-primary btn-rounded mt-3">Read More <i class="mdi mdi-arrow-right ml-1"></i></a>

                </div>
            </div>

            <div class="row pb-3 pt-5 align-items-center">
                <div class="col-lg-6">
                    <h3 class="font-weight-normal">Simple & Elegant design</h3>
                    <p class="text-muted mt-3">The simplest and fastest way to view and analyze recharge data. Pavathi is built using the latest technology and tools and provides an easy to use application.</p>

                    <div class="mt-4">
                        <p class="text-muted"><i class="mdi mdi-circle-medium text-success"></i> Built with Bootstrap</p>
                        <p class="text-muted"><i class="mdi mdi-circle-medium text-success"></i> Extensive use of CSS variables</p>
                        <p class="text-muted"><i class="mdi mdi-circle-medium text-success"></i> Well documented and structured code</p>
                        <p class="text-muted"><i class="mdi mdi-circle-medium text-success"></i> Detailed Documentation</p>
                    </div>

                    <a href="" class="btn btn-success btn-rounded mt-3">Read More <i class="mdi mdi-arrow-right ml-1"></i></a>

                </div>
                <div class="col-lg-5 offset-lg-1">
                    <img src={features2Img} class="img-fluid" alt="" />
                </div>
            </div>

        </div>
    </section>

    <section class="py-5 bg-light-lighten border-top border-bottom border-light" id='pricing'>
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="text-center">
                        <h1 class="mt-0"><i class="mdi mdi-tag-multiple"></i></h1>
                        <h3>Choose Simple <span class="text-primary">Pricing</span></h3>
                        <p class="text-muted mt-2">Pavathi software is offered as four plans, each with monthly & yearly buy options.
                            <br/>30 Day plans are offered at <b>₹299, ₹499, ₹799</b> & <b>₹999</b> each for Basic, Standard, Enterprises & Unlimited.</p>
                    </div>
                </div>
            </div>

            <div class="row mt-5 pt-3">
                <div class="col-md-3">
                    <div class="card card-pricing">
                        <div class="card-body text-center">
                            <p class="card-pricing-plan-name font-weight-bold text-uppercase">Basic </p>
                            <i class="card-pricing-icon dripicons-user text-primary"></i>
                            <h2 class="card-pricing-price">₹2990 <span>/ 365 Days</span></h2>
                            <ul class="card-pricing-features">
                                <li>Add upto 100 Lapu</li>
                                <li>Unlimited Providers</li>
                                <li>Unlimited Operators</li>
                                <li>Unlimited Users</li>
                                <li>Email Support</li>
                                <li>24x7 Support</li>
                            </ul>
                            <button class="btn btn-primary mt-4 mb-2 btn-rounded">Choose Plan</button>
                        </div>
                    </div>
                </div>

                <div class="col-md-3">
                    <div class="card card-pricing">
                        <div class="card-body text-center">
                            <p class="card-pricing-plan-name font-weight-bold text-uppercase">Standard </p>
                            <i class="card-pricing-icon dripicons-user text-primary"></i>
                            <h2 class="card-pricing-price">₹4990 <span>/ 365 Days</span></h2>
                            <ul class="card-pricing-features">
                                <li>Add upto 200 Lapu</li>
                                <li>Unlimited Providers</li>
                                <li>Unlimited Operators</li>
                                <li>Unlimited Users</li>
                                <li>Email Support</li>
                                <li>24x7 Support</li>
                            </ul>
                            <button class="btn btn-primary mt-4 mb-2 btn-rounded">Choose Plan</button>
                        </div>
                    </div>
                </div>

                <div class="col-md-3">
                    <div class="card card-pricing">
                        <div class="card-body text-center">
                            <p class="card-pricing-plan-name font-weight-bold text-uppercase">Enterprises </p>
                            <i class="card-pricing-icon dripicons-user text-primary"></i>
                            <h2 class="card-pricing-price">₹7990 <span>/ 365 Days</span></h2>
                            <ul class="card-pricing-features">
                                <li>Add upto 500 Lapu</li>
                                <li>Unlimited Providers</li>
                                <li>Unlimited Operators</li>
                                <li>Unlimited Users</li>
                                <li>Email Support</li>
                                <li>24x7 Support</li>
                            </ul>
                            <button class="btn btn-primary mt-4 mb-2 btn-rounded">Choose Plan</button>
                        </div>
                    </div>
                </div>
 
                <div class="col-md-3">
                    <div class="card card-pricing card-pricing-recommended">
                        <div class="card-body text-center">
                            <div class="card-pricing-plan-tag">Recommended</div>
                            <p class="card-pricing-plan-name font-weight-bold text-uppercase">Unlimited</p>
                            <i class="card-pricing-icon dripicons-briefcase text-primary"></i>
                            <h2 class="card-pricing-price">₹9990 <span>/ 365 Days</span></h2>
                            <ul class="card-pricing-features">
                              <li>Add Unlimited Lapu</li>
                              <li>Unlimited Providers</li>
                              <li>Unlimited Operators</li>
                              <li>Unlimited Users</li>
                              <li>Email Support</li>
                              <li>24x7 Support</li>
                            </ul>
                            <button class="btn btn-primary mt-4 mb-2 btn-rounded">Choose Plan</button>
                        </div>
                    </div>
                </div>

                {/* <div class="col-md-4">
                    <div class="card card-pricing">
                        <div class="card-body text-center">
                            <p class="card-pricing-plan-name font-weight-bold text-uppercase">Extended License</p>
                            <i class="card-pricing-icon dripicons-store text-primary"></i>
                            <h2 class="card-pricing-price">$599 <span>/ License</span></h2>
                            <ul class="card-pricing-features">
                                <li>100 GB Storege</li>
                                <li>Unlimited Bandwidth</li>
                                <li>10 Domain</li>
                                <li>Unlimited User</li>
                                <li>Email Support</li>
                                <li>24x7 Support</li>
                            </ul>
                            <button class="btn btn-primary mt-4 mb-2 btn-rounded">Choose Plan</button>
                        </div>
                    </div>
                </div> */}

            </div>

        </div>
    </section>

    <section class="py-5" id='faqs'>
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="text-center">
                        <h1 class="mt-0"><i class="mdi mdi-frequently-asked-questions"></i></h1>
                        <h3>Frequently Asked <span class="text-primary">Questions</span></h3>
                        <p class="text-muted mt-2">Here are some of the basic types of questions for our customers. For more 
                            <br/>information please contact us.</p>

                        <button type="button" class="btn btn-success btn-sm mt-2"><i class="mdi mdi-email-outline mr-1"></i> Email us your question</button>
                        <button type="button" class="btn btn-info btn-sm mt-2 ml-1"><i class="mdi mdi-twitter mr-1"></i> Send us a tweet</button>
                    </div>
                </div>
            </div>

            <div class="row mt-5">
                <div class="col-lg-5 offset-lg-1">
                    <div>
                        <div class="faq-question-q-box">Q.</div>
                        <h4 class="faq-question text-body">What type of view does Pavathi offer?</h4>
                        <p class="faq-answer mb-4 pb-1 text-muted">You can view Dashboard, Transactions and Reports and other pages with update feature.
                            Contact Us for more information.</p>
                    </div>

                    <div>
                        <div class="faq-question-q-box">Q.</div>
                        <h4 class="faq-question text-body">How do I get help with the Software?</h4>
                        <p class="faq-answer mb-4 pb-1 text-muted">Use our dedicated support email provided below to send your issues or feedback. We are here to help anytime.</p>
                    </div>

                </div>

                <div class="col-lg-5">
                    <div>
                        <div class="faq-question-q-box">Q.</div>
                        <h4 class="faq-question text-body">What are the current pricing of the software?</h4>
                        <p class="faq-answer mb-4 pb-1 text-muted">Please check the Pricing section in this page or contact us
                            for more details on the benefits.</p>
                    </div>

                    <div>
                        <div class="faq-question-q-box">Q.</div>
                        <h4 class="faq-question text-body">Will you regularly give updates of Pavathi?</h4>
                        <p class="faq-answer mb-4 pb-1 text-muted">Yes, we will update Pavathi software regularly.
                          We are aimed at providing better user experience.</p>
                    </div>

                </div>
            </div>

        </div>
    </section>

    <section class="py-5 bg-light-lighten border-top border-bottom border-light" id='contact'>
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="text-center">
                        <h3>Get In <span class="text-primary">Touch</span></h3>
                        <p class="text-muted mt-2">Please fill out the following form and we will get back to you shortly. For more 
                            <br/>information please contact us.</p>
                    </div>
                </div>
            </div>

            <div class="row align-items-center mt-3">
                <div class="col-md-4">
                    <p class="text-muted"><span class="font-weight-bold">Contact Number:</span><br/> <span class="d-block mt-1">+91 8073795822</span></p>
                    <p class="text-muted mt-4"><span class="font-weight-bold">Email Address:</span><br/> <span class="d-block mt-1">support@paperfreesolutions.com</span></p>
                    <p class="text-muted mt-4"><span class="font-weight-bold">Office Address:</span><br/> <span class="d-block mt-1">Udupi, Karnataka</span></p>
                    <p class="text-muted mt-4"><span class="font-weight-bold">Office Time:</span><br/> <span class="d-block mt-1">Mon-Sat | 9 AM - 7PM</span></p>
                </div>

                <div class="col-md-8">
                    <form>
                        <div class="row mt-4">
                            <div class="col-lg-6">
                                <div class="form-group">
                                    <label for="fullname">Your Name</label>
                                    <input class="form-control form-control-light" type="text" id="fullname" placeholder="Name..." />
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="form-group">
                                    <label for="emailaddress">Your Email</label>
                                    <input class="form-control form-control-light" type="email" required="" id="emailaddress" placeholder="Enter you email..." />
                                </div>
                            </div>
                        </div>

                        <div class="row mt-1">
                            <div class="col-lg-12">
                                <div class="form-group">
                                    <label for="subject">Your Subject</label>
                                    <input class="form-control form-control-light" type="text" id="subject" placeholder="Enter subject..." />
                                </div>
                            </div>
                        </div>

                        <div class="row mt-1">
                            <div class="col-lg-12">
                                <div class="form-group">
                                    <label for="comments">Message</label>
                                    <textarea id="comments" rows="4" class="form-control form-control-light" placeholder="Type your message here..."></textarea>
                                </div>
                            </div>
                        </div>

                        <div class="row mt-2">
                            <div class="col-12 text-right">
                                <button class="btn btn-primary" onClick={sendMessageHandler}>Send a Message <i
                                    class="mdi mdi-telegram ml-1"></i> </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>

    <footer class="bg-dark py-5">
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
              <img src="assets/images/logo.png" alt="" class="logo-dark" height="18" />
              <p class="text-white-50 mt-4">Pavathi software, an offering of Paperfree Solutions is an eagle eye
                  <br/> on data processing for multi-recharge systems. It is a complete 
                  <br/> accounting and tracking system software available in India.</p>

              <ul class="social-list list-inline mt-3">
                  <li class="list-inline-item text-center">
                      <a href="https://www.youtube.com/channel/UCTa5FRADL0CT2fmkhqGzIrg" target="_blank" class="social-list-item border-danger text-danger"><i class="uil uil-youtube"></i></a>
                  </li>
                  {/* <li class="list-inline-item text-center">
                      <a href="javascript: void(0);" class="social-list-item border-primary text-primary"><i class="mdi mdi-facebook"></i></a>
                  </li>
                  <li class="list-inline-item text-center">
                      <a href="javascript: void(0);" class="social-list-item border-danger text-danger"><i class="mdi mdi-google"></i></a>
                  </li>
                  <li class="list-inline-item text-center">
                      <a href="javascript: void(0);" class="social-list-item border-info text-info"><i class="mdi mdi-twitter"></i></a>
                  </li>
                  <li class="list-inline-item text-center">
                      <a href="javascript: void(0);" class="social-list-item border-secondary text-secondary"><i class="mdi mdi-github"></i></a>
                  </li> */}
              </ul>

          </div>

          <div class="col-lg-2 mt-3 mt-lg-0">
              <h5 class="text-white">Company</h5>

              <ul class="list-unstyled pl-0 mb-0 mt-3">
                  <li class="mt-2"><a href="javascript: void(0);" class="text-white-50">About Us</a></li>
                  <li class="mt-2"><a href="javascript: void(0);" class="text-white-50">Documentation</a></li>
                  <li class="mt-2"><a href="javascript: void(0);" class="text-white-50">Blog</a></li>
                  <li class="mt-2"><a href="javascript: void(0);" class="text-white-50">Affiliate Program</a></li>
              </ul>

          </div>

          <div class="col-lg-2 mt-3 mt-lg-0">
              <h5 class="text-white">Apps</h5>

              <ul class="list-unstyled pl-0 mb-0 mt-3">
                  <li class="mt-2"><a href="https://linesale.in/" target="_blank" class="text-white-50">Line Sale</a></li>
                  <li class="mt-2"><a href="https://pavathi.in/" target="_blank" class="text-white-50">Pavathi</a></li>
              </ul>
          </div>

          <div class="col-lg-2 mt-3 mt-lg-0">
              <h5 class="text-white">Discover</h5>

              <ul class="list-unstyled pl-0 mb-0 mt-3">
                    <li class="mt-2"><a href="javascript: void(0);" class="text-white-50">Help Center</a></li>
                    <li class="mt-2"><a href="javascript: void(0);" class="text-white-50">Our Products</a></li>
                    <li class="mt-2"><a href="javascript: void(0);" class="text-white-50" onClick={ e => modalOpenHandler('Refund Policy')}>Refund Policy</a></li>
                    <li class="mt-2"><a href="javascript: void(0);" class="text-white-50" onClick={ e => modalOpenHandler('Privacy Policy') }>Privacy Policy</a></li>
                    <li class="mt-2"><a href="javascript: void(0);" class="text-white-50" onClick={ e => modalOpenHandler('Terms and Conditions') }>Terms and Conditions</a></li>
                    { modalIsOpen && <Modal
                        modalName={modalName}
                        isModalOpened={modalIsOpen}
                        onCloseModal={modalCloseHandler}
                        onAfterOpen={handleAfterOpen}
                    /> }
              </ul>
          </div>
            </div>

            <div class="row">
                <div class="col-lg-12">
                    <div class="mt-5">
                        <p class="text-white-50 mt-4 text-center mb-0">© 2021 Paperfree Solutions. All rights reserved.</p>
                    </div>
                </div>
            </div>
        </div>
    </footer>

    <script src="assets/js/vendor.min.js"></script>
    <script src="assets/js/app.min.js"></script>

</div>
  );
}

export default App;
