import React from 'react';
import ReactModal from 'react-modal';

import PrivacyPolicy from '../../PrivacyPolicy/PrivacyPolicy';
import RefundPolicy from '../../RefundPolicy/RefundPolicy';
import TermsAndConditions from '../../TermsAndConditions/TermsAndConditions';

import classes from './Modal.module.css';

const Modal = props => {
    console.log('called')

    const customStyles = {
        content: {
            top: '46%',
            left: '50%',
            right: 'auto',
            width: '60%',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)'
        }
    };

    const afterOpenModal = event => {
        props.onAfterOpen(event, 'After Modal Opened');
    }
    
    const onModalClose = event => {
        props.onCloseModal('cancel');
    }

    return (
        <div>
            <ReactModal
                isOpen={props.isModalOpened}
                onAfterOpen={e => afterOpenModal(e)}
                style={customStyles}
                ariaHideApp={false}
                onRequestClose={props.onCloseModal}
                shouldCloseOnOverlayClick={true}
                shouldCloseOnEsc={true}
                overlayClassName={classes.overlay}
                className='modal-content'
            >
                <div className='modal-header'>
                    <h4>{props.modalName}</h4>
                    <button type='button' className='close' onClick={e => onModalClose(e)}>
                        <span aria-hidden='true'>&times;</span>
                    </button>
                </div>
                <div className='modal-body' style={{maxHeight: 'calc(82vh - 120px)', overflowY: 'auto'}}>
                    <div className='container'>
                        { props.modalName === 'Privacy Policy' && <PrivacyPolicy /> }
                        { props.modalName === 'Refund Policy' && <RefundPolicy /> }
                        { props.modalName === 'Terms and Conditions' && <TermsAndConditions /> }
                    </div>
                </div>
                <div className='modal-footer' style={{textAlign: 'right'}}>
                    <button className='btn btn-secondary' onClick={e => onModalClose(e)}>Close</button>
                </div>
            </ReactModal>
        </div>
    );

}

export default React.memo(Modal);