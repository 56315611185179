import { useState, useEffect } from 'react';

import classes from './TermsAndConditions.module.css';
import TERMS_N_CONDITIONS from '../../_data/termsNConditionsData';

const TermsAndConditions = props => {
    
    const [termsNConditions, setTermsNConditions] = useState([]);
    
    useEffect(() => {
        setTermsNConditions(TERMS_N_CONDITIONS);
    }, []);

    return (
        <>
            { termsNConditions.map( (data, index) => {
                return (
                    <div className={classes.termsNConditionsContent}>
                        <h4 style={{textDecoration:'underline'}}>{index+1}. {data.title}</h4>
                        <p className={classes.textJustify}>{data.text}</p>
                    </div>
                )
            })}
        </>
    );
}

export default TermsAndConditions;