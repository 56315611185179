import classes from './RefundPolicy.module.css';

const RefundPolicy = props => {

    return (
        <>
            <div className={classes.refundContent}>
                <p>
                    Thank you for Registering at PaperFree solutions. Please read below Refund policy carefully. We suggest you go through the Terms and Conditions as well. This is the Refund Policy of Paper Free solutions.<br/><br/>
                    <h5>Digital products:</h5>
                    We do not issue refund for digital products once the order is confirmed and the product is sent. As we are service providers, please use monthly subscription to test the digital software related services we offer.
                </p>
                <h4>Refund</h4>
                <p className={classes.refundText}>
                    Once the plan is selected and purchased, we are liable only to the services available in the website. Any customization will be done at a separate cost. Once the payment is done, there will not be any refund. To ensure customer satisfaction, we advice customers to initially select monthly plans, use our services and if satisfied opt for yearly plans and make the payment accordingly. Once the payment done, customer is entitled to use the given services only. No refund request will be entertained. If we notice any type of fraud, we will block the customer's account and no refund will be provided.
                    We recommend contacting us for assistance if you experience any issues while using our products.<br/><br/>
                    <h5>Contact us:</h5>
                    If you have any questions about our Returns and Refunds Policy, please contact us by email: <a href='support@paperfreesolutions.com'>support@paperfreesolutions.com</a>
                </p>
            </div>
        </>
    );
}

export default RefundPolicy;