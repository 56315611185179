import classes from './PrivacyPolicy.module.css';

const PrivacyPolicy = props => {

    return (
        <>
            <div className={classes.privacyPolicyContent}>
                <p>
                    PaperFree solutions is committed to privacy and protection of your information. In order to endow you with our uninterrupted use of services, we collect very limited data about you, and we do not disclose information collected from you. To enhance better protection of your privacy we provide this notice explaining our information practices and the choices you can make about the way your information is collected and used.
                </p>
            </div>
            <div className={classes.privacyPolicyContent}>
                <h4 style={{textDecoration:'underline'}}>OVERVIEW</h4>
                <p className={classes.textJustify}>
                    We commit to respecting your online data privacy. We further recognize your need for appropriate protection and management of any personally identifiable information ("Personal Information") you share with us. Information that is considered personal about you by us includes, but is not limited to, your name, address, email address or other contact information.<br/><br/>
                    In order to use this website or App, you may be required to register yourself by providing the following information which includes, but is not limited to:<br/><br/>
                    (1) Name;<br/>
                    (2) User name;<br/>
                    (3) Password;<br/>
                    (3) Password;<br/>
                    (4) Mobile number;<br/>
                    (5) Web Address;<br/>
                    (6) Email;<br/>
                    (7) Your website IP/ if requierd;<br/><br/>
                    Kindly note that the list above is merely illustrative only and that you may be required to provide other information during registration.
                </p>
            </div>
            <div className={classes.privacyPolicyContent}>
                <h4 style={{textDecoration:'underline'}}>NOTIFICATION OF MODIFICATIONS AND CHANGES TO THE T&C AND PRIVACY POLICY</h4>
                <p className={classes.textJustify}>
                    We reserve the right to change the Terms and Privacy Policy from time to time as seen fit, without any intimation to you, and your continued use of the site or App will signify your acceptance of any amendment to these terms.
                    <br/><br/>
                    You are therefore advised to re-read the Terms of Service and Privacy Policy on a regular basis. Should it be that you do not accept any of the modifications or amendments to the Terms, you may terminate your use of this website and app on mobile devices immediately.
                </p>
            </div>
            <div className={classes.privacyPolicyContent}>
                <h4 style={{textDecoration:'underline'}}>INFORMATION WE COLLECT</h4>
                <p className={classes.textJustify}>
                    Our site's or App’s online registration form requires users to give us contact information (like your name, address, telephone number and email address), and demographic information (like your zip code/pin code). As a ember you are required to provide a valid email address at registration and choose a username or alias that represents your identity on our website and app on mobile devices.
                </p>
            </div>
            <div className={classes.privacyPolicyContent}>
                <h4 style={{textDecoration:'underline'}}>OUR USE OF YOUR INFORMATION</h4>
                <p className={classes.textJustify}>
                    Your contact information is used to contact you when necessary. We use your IP address to help diagnose problems with our server, and to administer our website. Your IP address is also used to help identify you and to gather broad demographic information. We may use your IP address to help protect our partners and ourselves from fraud.<br/><br/>
                    We may release your personal information to a third-party in order comply with a Court Order or other similar legal procedure, or when we believe in good faith that such disclosure is necessary to comply with the law; prevent imminent physical harm or financial loss; or investigate or take action regarding illegal activities, suspected fraud, or violations of Our Terms of Use. We may disclose certain information about you, your recharge information and data analytics to our business partners.<br/><br/>
                    By way of example (without limiting and foregoing), third parties may sometimes unlawfully intercept or access transmissions, or other parties may abuse or misuse your information that they collect from our Websites. Therefore, we do not promise, and you should not expect, that any information that we collect about you or private communications would always remain private.
                </p>
            </div>
            <div className={classes.privacyPolicyContent}>
                <h4 style={{textDecoration:'underline'}}>ACCESSING, REVIEWING AND CHANGING YOUR PROFILE</h4>
                <p className={classes.textJustify}>
                    Following registration, you can review and change the information you submitted except your display name, during registration. If you change any information we may keep track of your old information. You can change your registration information such as: name, address, city, state, zip code, country, phone number, and profile.<br/><br/>
                    To remove your profile so that others cannot view it, contact our customer support team at Paper Free solution, <a href='support@paperfreesolutions.com'>support@paperfreesolutions.com</a>.<br/><br/>
                    We will retain in our files information you have requested to remove for certain circumstances, such as to resolve disputes, troubleshoot problems and enforce our terms and conditions. Further, such prior information is never completely removed from our databases due to technical and legal constraints, including stored 'back up' systems. Therefore, you should not expect that all of your personally identifiable information will be completely removed from our databases in response to your requests.
                </p>
            </div>
            <div className={classes.privacyPolicyContent}>
                <h4 style={{textDecoration:'underline'}}>OTHER INFORMATION COLLECTORS</h4>
                <p className={classes.textJustify}>
                    Except as otherwise expressly included in this Privacy Policy, this document only addresses the use and disclosure of information we collect from you. To the extent that you disclose your information to other parties, whether they are on our websites or App or on other sites throughout the Internet, different rules may apply to their use or disclosure of the information you disclose to them. To the extent that we use third party advertisers, they adhere to their own privacy policies. Since we do not control the privacy policies of the third parties, you are subject to ask questions before you disclose your personal information to others.<br/><br/>
                    If you have any questions or concerns regarding this privacy policy, you should contact us at <a href='support@paperfreesolutions.com'>support@paperfreesolutions.com</a>.
                </p>
            </div>
        </>
    );
}

export default PrivacyPolicy;